export const CONFIG = {

  auth: {
    loginLogoPath: require('@/assets/config/logo.svg'),
    register: {
      subtitle: 'Заявку рассмотрит наш менеджер и отправит Вам на почту результат проверки.',
      hint: 'Проблемы с регистрацией? Напишите нам на&nbsp;<a href="mailto:info@tdc-tools.ru" target="_blank">info@tdc-tools.ru</a>',
      checkboxes: [
        /** ID должен начинаться с префикса cb_ */
        {
          id: 'cb_privacy',
          html: 'Согласен на&nbsp;<a href="/upload/b2b_load/policy.html" target="_blank">обработку персональных данных</a>'
        },
      ],
    },
  },

  layoutStandart: {
    headerContacts: [
        '+7 495 741 86 72',
        'info@tdc-tools.ru',
    ],
    headerLogoPath: require('@/assets/config/logo.svg'),
  },

  navWatcher: {
    titleSuffix: ' | TDC Shop',
  },

  dashboard: {
    showRightColumn: false,
  },

  catalog: {
    defaultItemView: 'list', // 'list' | 'grid'
    showRetailPrice: true,
    showSubcategories: true,
    itemCountStep: 100,
    applyFiltersOnChange: true
  },

  print: {
    logoPath: require('@/assets/config/logo.svg'),
  },

  banners: {
    height: '400px',
  },

  global: {
    country: 'RU',
    priceTitle: 'Оптовая цена'
  },

  warehouses: {
    showQuantity: true,
  },

  tables: {
    articleFieldWidth: 110,
    priceFieldWidth: 150,
    nameFieldLabel: 'Название'
  },

  usersTable: {
    showINN: false,
  },

  orders: {
    detailed: {
      downloadInvoice: {
        showWithFirstStatus: false,
      }
    }
  }
}
