import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Fragment as _Fragment, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7c38c24a"), n = n(), _popScopeId(), n);

const _hoisted_1 = ["src"];
const _hoisted_2 = {
  class: "quantity"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  class: "remove"
};
const _hoisted_5 = {
  key: 0,
  style: {
    "font-weight": "bold"
  }
};
const _hoisted_6 = {
  key: 1
};
import ItemAvailability from "@/features/ui/ItemAvailability.vue";
import { watch } from "vue";
import { moneyFormat } from "@/features/utils/utils.js";
import { Icon } from "@iconify/vue";
import { CONFIG } from "@/config.js";
import { useBasketItems } from "@/features/baskets/useBasketItems";
export default {
  __name: 'BasketItemsView',
  props: {
    'disableChangeAmount': Boolean,
    'basketId': String,
    'orderId': String,
    'isSmall': Boolean
  },

  setup(__props) {
    const props = __props;
    const {
      tableData,
      loading,
      possibleToChangeAmounts,
      removeBundle,
      updateBundles,
      bufferAmounts,
      showUpdateButton,
      canChangeAmount,
      link,
      confirmBuffer,
      resetBuffer
    } = useBasketItems();
    watch(props, p => {
      updateBundles(p);
    }, {
      immediate: true
    });
    return (_ctx, _cache) => {
      const _component_el_table_column = _resolveComponent("el-table-column");

      const _component_router_link = _resolveComponent("router-link");

      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_popconfirm = _resolveComponent("el-popconfirm");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_container = _resolveComponent("el-container");

      const _component_el_table = _resolveComponent("el-table");

      const _directive_loading = _resolveDirective("loading");

      return _withDirectives((_openBlock(), _createBlock(_component_el_container, {
        class: "basket-items-view"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_table, {
          data: _unref(tableData),
          "table-layout": "auto"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_table_column, {
            prop: "a",
            label: "Артикул",
            width: _unref(CONFIG).tables.articleFieldWidth
          }, null, 8, ["width"]), _createVNode(_component_el_table_column, {
            prop: "photo",
            width: "60"
          }, {
            default: _withCtx(scope => [scope.row.photo ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              src: scope.row.photo,
              alt: "",
              height: "40",
              width: "40",
              class: "imgPhoto"
            }, null, 8, _hoisted_1)) : _createCommentVNode("", true)]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "title",
            label: _unref(CONFIG).tables.nameFieldLabel
          }, {
            default: _withCtx(scope => [_createVNode(_component_router_link, {
              class: _normalizeClass(["offer-title", {
                'small': __props.isSmall && _unref(CONFIG).tables.articleFieldWidth === 300
              }]),
              to: _unref(link)(scope.row)
            }, {
              default: _withCtx(() => [_createTextVNode(_toDisplayString(scope.row.title), 1)]),
              _: 2
            }, 1032, ["class", "to"]), scope.row.offerTitle ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["offer-title", {
                'small': __props.isSmall && _unref(CONFIG).tables.articleFieldWidth === 300
              }])
            }, _toDisplayString(scope.row.offerTitle), 3)) : _createCommentVNode("", true)]),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_table_column, {
            prop: "availability",
            label: "Наличие",
            width: "140"
          }, {
            default: _withCtx(scope => [_createVNode(ItemAvailability, {
              availability: scope.row.availability
            }, null, 8, ["availability"])]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "price",
            label: _unref(CONFIG).global.priceTitle,
            width: "145"
          }, {
            default: _withCtx(scope => [_createElementVNode("div", null, _toDisplayString(scope.row.price), 1)]),
            _: 1
          }, 8, ["label"]), _createVNode(_component_el_table_column, {
            prop: "amount",
            label: "Кол-во",
            width: "100"
          }, {
            default: _withCtx(scope => [_createElementVNode("div", _hoisted_2, [!_unref(possibleToChangeAmounts)(props) ? (_openBlock(), _createElementBlock("div", _hoisted_3, [scope.row?.amount ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 0
            }, [_createTextVNode(_toDisplayString(scope.row.amount), 1)], 64)) : _unref(bufferAmounts)[scope.row?.id] ? (_openBlock(), _createElementBlock(_Fragment, {
              key: 1
            }, [_createTextVNode(_toDisplayString(_unref(bufferAmounts)[scope.row.id]), 1)], 64)) : _createCommentVNode("", true)])) : _createCommentVNode("", true), _unref(possibleToChangeAmounts)(props) ? (_openBlock(), _createBlock(_component_el_input_number, {
              key: 1,
              modelValue: _unref(bufferAmounts)[scope.row.id],
              "onUpdate:modelValue": $event => _unref(bufferAmounts)[scope.row.id] = $event,
              min: 0,
              size: "small",
              disabled: !_unref(canChangeAmount)(scope.row)
            }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])) : _createCommentVNode("", true), _unref(possibleToChangeAmounts)(props) ? (_openBlock(), _createBlock(_component_el_popconfirm, {
              key: 2,
              title: "Подтвердите удаление позиции",
              onConfirm: $event => _unref(removeBundle)(scope.row),
              "confirm-button-text": "Удалить",
              "cancel-button-text": "Отмена"
            }, {
              reference: _withCtx(() => [_createElementVNode("div", _hoisted_4, [_createVNode(_unref(Icon), {
                icon: "bx:trash-alt",
                class: "icon"
              })])]),
              _: 2
            }, 1032, ["onConfirm"])) : _createCommentVNode("", true)])]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            label: "Сумма",
            width: "140"
          }, {
            default: _withCtx(scope => [_unref(showUpdateButton)(scope.row) ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_unref(moneyFormat)(scope.row.priceRaw, {
              valueMultiplier: _unref(bufferAmounts)[scope.row.id]
            })), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(scope.row.total), 1))]),
            _: 1
          }), _createVNode(_component_el_table_column, {
            prop: "actions"
          }, {
            default: _withCtx(scope => [!__props.disableChangeAmount ? (_openBlock(), _createBlock(_component_el_container, {
              key: 0,
              class: "actions"
            }, {
              default: _withCtx(() => [_unref(showUpdateButton)(scope.row) ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "primary",
                size: "small",
                onClick: $event => _unref(confirmBuffer)(scope.row)
              }, {
                default: _withCtx(() => [_createVNode(_unref(Icon), {
                  icon: "ci:check-bold",
                  class: "icon"
                })]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true), _unref(showUpdateButton)(scope.row) ? (_openBlock(), _createBlock(_component_el_button, {
                key: 1,
                type: "danger",
                size: "small",
                plain: "",
                onClick: $event => _unref(resetBuffer)(scope.row),
                style: {
                  "margin-left": "5px"
                }
              }, {
                default: _withCtx(() => [_createVNode(_unref(Icon), {
                  icon: "ci:undo",
                  class: "icon"
                })]),
                _: 2
              }, 1032, ["onClick"])) : _createCommentVNode("", true)]),
              _: 2
            }, 1024)) : _createCommentVNode("", true)]),
            _: 1
          })]),
          _: 1
        }, 8, ["data"])]),
        _: 1
      })), [[_directive_loading, _unref(loading)]]);
    };
  }

};